export class BaseException {
    public readonly type: string;

    public readonly statusCode: number;

    public readonly message: string =
        'An unknown error has occurred. Please try again later.';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(error: any) {
        this.type = error.type;
        this.statusCode = error.statusCode;
        if (error.message) {
            this.message = Array.isArray(error.message)
                ? error.message.join('\n')
                : error.message;
        }
    }
}

export enum BidNotAllowedErrorCode {
    ItemSoldOut = 'item_sold_out',
    QuantityNotAvailable = 'quantity_not_available',
    AuctionNotStarted = 'auction_not_started',
    AuctionDone = 'auction_done',
    AuctionProcessing = 'auction_processing',
    AuctionExtendedNoAccess = 'auction_extended_no_access'
}

export class UnknownException extends BaseException {
    constructor() {
        super({
            type: UnknownException.name,
            statusCode: -1,
        });
    }
}

export class UnauthorizedError extends BaseException {}

export class TwoFactorRequiredException extends BaseException {}

export class UserBlockedException extends BaseException {}

export class BadRequestException extends BaseException {}

export class ForbiddenException extends BaseException {}

export class NotAcceptableException extends BaseException {}

export class InsufficientCreditLimitException extends BaseException {}

export class BidNotAllowedException extends BaseException {
    public readonly code: BidNotAllowedErrorCode;

    constructor(error: any) {
        super(error);
        this.code = error.code;
    }
}

export class BuyNotAllowedException extends BaseException {
    public readonly code: BidNotAllowedErrorCode;

    constructor(error: any) {
        super(error);
        this.code = error.code;
    }
}

export class ConflictException extends BaseException {
    public readonly meta?: { keys?: string[] };

    constructor(error: any) {
        super(error);
        this.meta = error.meta;
    }
}

export class InternalServerErrorException extends BaseException {}

export class NotFoundException extends BaseException {}

export class MissingAddressException extends NotFoundException {}

export class CouponNotFoundException extends NotFoundException {}

export class CouponInvalidException extends NotAcceptableException {}

export class PayloadTooLargeException extends BaseException {}

export class ServiceUnavailableException extends BaseException {}

export class UnauthorizedException extends BaseException {}

export class UnprocessableEntityException extends BaseException {}

export class EmailNotVerifiedException extends BaseException {}

export class UnsupportedMediaTypeException extends BaseException {}

export class ThrottlerException extends BaseException {}

export class NonLatinCharacterException extends BaseException {}
