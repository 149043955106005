import {
    BadRequestException,
    BaseException,
    BidNotAllowedException,
    BuyNotAllowedException,
    ConflictException,
    CouponInvalidException,
    CouponNotFoundException,
    EmailNotVerifiedException,
    ForbiddenException,
    InsufficientCreditLimitException,
    InternalServerErrorException,
    MissingAddressException,
    NonLatinCharacterException,
    NotFoundException,
    PayloadTooLargeException,
    ServiceUnavailableException,
    ThrottlerException,
    TwoFactorRequiredException,
    UnauthorizedError,
    UnauthorizedException,
    UnknownException,
    UnprocessableEntityException,
    UnsupportedMediaTypeException,
    UserBlockedException,
} from './Exceptions';

export abstract class ErrorHandler {
    private constructor() {
        /* noop */
    }

    static transformException(error: BaseException) {
        switch (error.type) {
            case 'UnauthorizedError':
                return new UnauthorizedError(error);
            case 'TwoFactorRequiredException':
                return new TwoFactorRequiredException(error);
            case 'UserBlockedException':
                return new UserBlockedException(error);
            case 'BadRequestException':
                return new BadRequestException(error);
            case 'ForbiddenException':
                return new ForbiddenException(error);
            case 'InsufficientCreditLimitException':
                return new InsufficientCreditLimitException(error);
            case 'ConflictException':
                return new ConflictException(error);
            case 'InternalServerErrorException':
                return new InternalServerErrorException(error);
            case 'BidNotAllowedException':
                return new BidNotAllowedException(error);
            case 'BuyNotAllowedException':
                return new BuyNotAllowedException(error);
            case 'NotFoundException':
                return new NotFoundException(error);
            case 'MissingAddressException':
                return new MissingAddressException(error);
            case 'CouponNotFoundException':
                return new CouponNotFoundException(error);
            case 'CouponInvalidException':
                return new CouponInvalidException(error);
            case 'PayloadTooLargeException':
                return new PayloadTooLargeException(error);
            case 'ServiceUnavailableException':
                return new ServiceUnavailableException(error);
            case 'UnauthorizedException':
                return new UnauthorizedException(error);
            case 'UnprocessableEntityException':
                return new UnprocessableEntityException(error);
            case 'EmailNotVerifiedException':
                return new EmailNotVerifiedException(error);
            case 'UnsupportedMediaTypeException':
                return new UnsupportedMediaTypeException(error);
            case 'ThrottlerException':
                return new ThrottlerException(error);
            case 'NonLatinCharacterException':
                return new NonLatinCharacterException(error);
            default:
                return new UnknownException();
        }
    }

    static async transformError(e: Request | Error) {
        if (e instanceof Error) {
            return new UnknownException();
        }
        try {
            const error = await e.clone().json();

            return ErrorHandler.transformException(error);
        } catch (error) {
            return new UnknownException();
        }
    }

    static Auction = class {};

    static Auth = class {
        static emailNotVerified = (e: BaseException) => {
            return e instanceof EmailNotVerifiedException;
        };

        static unauthorized = (e: BaseException) => {
            return e instanceof UnauthorizedException;
        };

        static blocked = (e: BaseException) => {
            return e instanceof UserBlockedException;
        };

        static badOtp = (e: BaseException) => {
            return e instanceof BadRequestException;
        };

        static badResetPwToken = (e: BaseException) => {
            return e instanceof NotFoundException;
        };

        static emailExist = (e: BaseException) => {
            return (
                e instanceof ConflictException &&
                e.meta?.keys?.includes('email-address')
            );
        };

        static usernameExist = (e: BaseException) => {
            return (
                e instanceof ConflictException &&
                e.meta?.keys?.includes('username')
            );
        };
    };
}
