'use client';

import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import Logo from '@/components/general/Logo';
import useAuth from '@/contexts/AuthContext';
import { usePathname } from 'next/navigation';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { trackClick } from '@/helpers/Analytics';
import { CMS } from '@/types/CMS';
import SmallLogo from '@/components/general/LogoSmall';
import Container from '../Container';
import MobileNavigation from './MobileNavigation';
import NavLink from './NavLink';
import PredictiveSearch from './PredictiveSearch';
import LoginOrSignUp from './LoginOrSignUp';
import LoggedInUser from './LoggedInUser';
import Alerts from './Alerts/Alerts';
import MobileNavIcon from './MobileNavIcon';

type IFooterProps = {
    logoUrl: string | null;
    mobileLogoUrl?: string | null;
    navigation: CMS.Global.Navigation;
};

const Header = (props: IFooterProps) => {
    const { navigation, logoUrl, mobileLogoUrl } = props;
    const { user } = useAuth();
    const [mobileMenuOopen, setMobileMenuOopen] = useState<boolean>(false);
    const [mobileSearchOopen, setMobileSearchOpen] = useState<boolean>(false);

    const pathname = usePathname();

    useEffect(() => {
        setMobileMenuOopen(false);
    }, [pathname]);

    useEffect(() => {
        if (!mobileMenuOopen) {
            setMobileSearchOpen(false);
        }
    }, [mobileMenuOopen]);

    return (
        <header style={{ borderBottom: '1px solid #e6e6e6', }} className="md:py-4 py-[10px] fixed top-0 right-0 left-0 md:h-[80px] h-[68px] bg-white z-50 flex items-center">
            <Container className="tablet:!px-[14px] w-full">
                <nav className="relative z-50 md:grid md:grid-cols-[45%_10%_45%] flex justify-between">
                    <div className={clsx('hidden md:flex lg:gap-x-2 md:gap-x-6 mr-8 tablet:mr-4 tablet:gap-x-2 items-center overflow-hidden flex-wrap')}>
                        {navigation.mainMenu.map((menuItem) => (
                            <NavLink
                                key={menuItem.id}
                                href={menuItem.path}
                                external={menuItem.external}
                            >
                                {menuItem.title}
                            </NavLink>
                        ))}
                    </div>
                    <div className="flex items-center justify-center md:gap-x-12">
                        <Link onClick={trackClick('Home')} prefetch={false} href="/" aria-label="Home">
                            {logoUrl ?
                                <>
                                    <img className="max-h-[40px] w-auto z-10 relative block md:hidden lg:block" alt="Logo" src={logoUrl} />
                                    <img className="[&>path]:fill-main h-[40px] w-auto z-10 relative hidden md:block lg:hidden" alt="Logo" src={mobileLogoUrl || logoUrl} />
                                </>
                                :
                                <>
                                    <Logo className="max-h-[40px] w-auto z-10 relative block md:hidden lg:block" />
                                    <SmallLogo className="[&>path]:fill-main h-[40px] w-auto z-10 relative hidden md:block lg:hidden" />
                                </>
                            }
                        </Link>
                    </div>
                    <div className="flex items-center justify-end gap-x-5 md:gap-x-3 ">

                        <div className="justify-between items-center gap-x-3 relative z-10 hidden md:flex">
                            <PredictiveSearch />
                        </div>

                        <div className="justify-between items-center gap-x-3 relative z-10 md:hidden flex">
                            <div className={clsx('p-[6px] rounded-full', mobileSearchOopen ? 'bg-main text-white' : 'bg-white text-main')}>
                                <MagnifyingGlassIcon
                                    width="20"
                                    height="20"
                                    onClick={() => {
                                        setMobileMenuOopen(!mobileSearchOopen);
                                        setMobileSearchOpen(!mobileSearchOopen);
                                    }}
                                />
                            </div>
                            {user &&
                                <>
                                    <Alerts onOpen={() => {
                                        trackClick('Open Alerts')();
                                        setMobileMenuOopen(false);
                                        setMobileSearchOpen(false);
                                    }} /><LoggedInUser onOpen={() => {
                                        setMobileMenuOopen(false);
                                        setMobileSearchOpen(false);
                                    }} />
                                </>
                            }
                        </div>

                        <div className="hidden md:flex">{user ?
                            <>
                                <Alerts /><LoggedInUser />
                            </> :
                            <LoginOrSignUp />
                        }
                        </div>
                        <button className="md:hidden" onClick={() => setMobileMenuOopen(!mobileMenuOopen)}>
                            <MobileNavIcon open={mobileMenuOopen} />
                        </button>
                    </div>
                </nav>
            </Container>

            <div className="-mr-1 md:hidden">
                <MobileNavigation search={mobileSearchOopen} open={mobileMenuOopen} mainMenu={navigation.mainMenu} />
            </div>
        </header>
    );
};

export default Header;
