import { AuctionApi, BidDto, ExpressBuyDto, ExpressEstimateDto } from '@/api';
import { apiConfiguration } from '@/api/config';

export const auctionApi = new AuctionApi(apiConfiguration);

export const getAuctionsQueryFn = async () =>
    auctionApi.auctionControllerGetAuctions();

export const bidOnAuctionQueryFn = async (data: {
    data: BidDto;
    auctionId: string;
}) => auctionApi.auctionControllerBidOnAuction(data.data, data.auctionId);

export const getAuctionBidHistoryQueryFn = async (
    id: string,
    offset: number,
    limit: number
) => auctionApi.auctionControllerGetAuctionBidHistory(id, offset, limit);

export type UserAuctionsBidsHistoryResponse = Awaited<ReturnType<typeof auctionApi.auctionControllerGetUserBidHistory>>;

export const getUserAuctionsBidsHistoryQueryFn = async (
    offset: number,
    limit: number
) => auctionApi.auctionControllerGetUserBidHistory(offset, limit);

export const getlastBidFn = async (auctionid: string) =>
    auctionApi
        .auctionControllerGetAuctionLastBid(auctionid)
        .then((res) => res || null);

export const checkAuctionAccessFn = async (auctionid: string) =>
    auctionApi.auctionControllerGetAuctionAccess(auctionid);

export const getExpressEstimateFn = async (data: {
    dto: ExpressEstimateDto;
    auctionId: string;
}) =>
    auctionApi.auctionControllerBuyEstimateItemExpress(
        data.dto,
        data.auctionId
    );

export const buyExpressFn = async (data: {
    dto: ExpressBuyDto;
    auctionId: string;
}) => auctionApi.auctionControllerBuyItemExpress(data.dto, data.auctionId);
